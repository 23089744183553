import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";

const Wrapper = styled.div`
  @media (min-width: 1200px) {
    padding-top: 25px;
    padding-bottom: 50px;
    margin: 0 auto;
    margin-bottom: -450px;
    background-color: whitesmoke;
    position: relative;
    z-index: 11;
  }
`;

const statute = () => (
  <Layout>
    <Wrapper>
      {" "}
      <div className="txt">
        <div className="txt-container" style={{ marginBottom: "50px" }}>
          <h1>Regulamin serwisu internetowego </h1>
          <h2>jbmultimedia.pl</h2>
        </div>
        <h2>Definicje</h2>
        <p>
          <strong>JB Multimedia Jerzy Bielecki</strong>: JB Multimedia Jerzy
          Bielecki z siedzibą przy ul. Brzeskiej 107a, 21-500 Biała Podlaska
          zarejestrowaną przez Urząd Gminy Biała Podlaska w Centralnej Ewidencji
          i Informacji o Działalności Gospodarczej, o numerze NIP 537-123-42-90
          i numerze REGON 030156724.
        </p>
        <p>
          <strong>Regulamin</strong>: niniejszy regulamin stanowiący, regulamin,
          o którym mowa w art. 8 ustawy z dnia 18 lipca 2002 r. o świadczeniu
          usług drogą elektroniczną (t. jedn. Dz. U. z 2013, poz. 1422),
          określający zasady świadczenia usług drogą elektroniczną w Serwisie.
        </p>
        <p>
          <strong>Serwis</strong>: internetowy serwis dostępny pod adresem:
          www.jbmultimedia.pl, przeznaczonym dla osób zainteresowanych
          zapoznaniem się z ofertą sprzętu komputerowego i rozwiązań
          informatycznych.
        </p>
        <p>
          <strong>Użytkownik</strong>: korzystająca z Serwisu osoba fizyczna,
          pełnoletnia oraz posiadająca pełną zdolność do czynności prawnych albo
          osoba fizyczna ograniczona w zdolności do czynności prawnych, która
          ukończyła lat 15 i korzysta z Serwisu tylko za zgodą przedstawiciela
          ustawowego, (np. rodzica, opiekuna, kuratora).
        </p>

        <h2>§ 1 Postanowienia ogólne</h2>

        <p>
          Właścicielem i administratorem Serwisu jest JB Multimedia Jerzy
          Bielecki.
        </p>

        <p>
          JB Multimedia Jerzy Bielecki posiada wszelkie prawa do nazwy, domeny,
          układu, elementów graficznych oraz wszelkich innych twórczych
          elementów serwisu.
        </p>

        <p>
          Serwis służy celom informacyjnym ukierunkowanych na przedstawienie
          oferty JB Multimedia Jerzy Bielecki i umożliwienie odnalezienia
          kontaktu.
        </p>

        <p>
          Szczegółowe zasady korzystania z usług oferowanych w ramach Serwisu
          oraz zasady korzystania z Serwisu określa niniejszy Regulamin.
        </p>

        <p>
          Korzystanie z Serwisu jest możliwe pod warunkiem spełnienia przez
          system teleinformatyczny Klienta następujących wymagań technicznych:
        </p>
        <ol type="a">
          <li>
            zastosowania przeglądarek: przeglądarki Internet Explorer 8, Chrome,
            Safari, Firefox lub innych nowszych wersji oraz oprogramowania
            akceptującego pliki cookies.
          </li>
          <li>dostęp do sieci Internet.</li>
        </ol>

        <p>
          JB Multimedia Jerzy Bielecki świadczy za pośrednictwem Serwisu
          następujące usługi:
        </p>
        <ol type="a">
          <li>
            umożliwia zapoznanie się z treściami opublikowanymi na stronach
            Serwisu
          </li>
          <li>
            umożliwia kontakt z bezpośrednimi działami firmy poprzez formularz
            kontaktowy
          </li>
          <li>przejście do innych serwisów</li>
          <li>
            zapisanie się do newslettera w celu otrzymywania informacji o
            promocjach i aktualnościach
          </li>
        </ol>

        <p>
          Podczas korzystania ze Serwisu Użytkownik powinien w szczególności
          przestrzegać zakazu dostarczania z użyciem środków komunikacji
          elektronicznej treści powodujących zakłócenie pracy lub przeciążenie
          systemów teleinformatycznych oraz naruszających dobra osób trzecich,
          ogólnie przyjęte normy społeczne lub niezgodnych z powszechnie
          obowiązującymi przepisami prawa.
        </p>

        <h2>§ 2 Korzystanie z Serwisu</h2>

        <p>
          Korzystanie z serwisu oznacza zgodę na postanowienia regulaminu. Każdy
          użytkownik powinien zapoznać się z treścią regulaminu przed
          rozpoczęciem korzystania z serwisu. Użytkownik, który nie wyraża zgody
          na postanowienia regulaminu powinien zaprzestać korzystania z serwisu.
        </p>

        <p>
          W wyniku korzystania z Serwisu Użytkownik nie nabywa jakichkolwiek do
          niego praw.
        </p>

        <h2>§ 3 Korzystanie z formularza kontaktowego w Serwisie</h2>

        <p>
          Użytkownik ma możliwość zgłoszenia Materiałów do publikacji w Serwisie
          poprzez formularz kontaktowy znajdujący się w serwisie.
        </p>

        <p>Wysłanie wiadomości przez Użytkownika wymaga podania:</p>
        <ol type="a">
          <li>imię i nazwisko</li>
          <li>numer telefonu</li>
          <li>aktywny adres e-mailowy z możliwością logowania się do niego</li>
          <li>wpisania treści wiadomości</li>
        </ol>

        <p>
          Dokonując zgłoszenia przez formularz kontaktowy w Serwisie Użytkownik
          w formularzu zgłoszenia:
        </p>
        <ol type="a">
          <li>
            wyraża zgodę na przetwarzanie przez JB Multimedia Jerzy Bielecki
            danych osobowych zawartych w formularzu kontaktowym w celu
            otrzymania odpowiedzi na zadane pytanie.
          </li>
          <li>
            Użytkownik umieszcza informacje nie wprowadzające w błąd oraz nie
            naruszające świadomie i umyślnie jakichkolwiek praw osób trzecich, w
            tym praw autorskich, praw do znaków towarowych oraz dóbr osobistych
            osób trzecich.
          </li>
        </ol>

        <h2>§ 4 Dane osobowe</h2>

        <p>
          1. Na potrzeby funkcjonowania Serwisu www.jbmultimedia.pl przetwarza
          następujące dane osobowe Użytkowników:
        </p>
        <ol type="a">
          <li>imię i nazwisko,</li>
          <li>numer telefonu,</li>
          <li>adres e-mail,</li>
          <li>adres IP.</li>
        </ol>

        <p>
          2. Dane osobowe Użytkowników wykorzystywane są przez JB Multimedia
          Jerzy Bielecki w celu prowadzenia Serwisu, w szczególności
          umożliwienia świadczenia Użytkownikom usług oferowanych w ramach
          Serwisu oraz w celu zapewnienia sprawnego funkcjonowania Serwisu.
        </p>

        <p>
          3. Za zgodą Użytkownika jego dane osobowe znajdują się w bazie
          Serwisu. Administratorem danych osobowych Użytkowników jest JB
          Multimedia Jerzy Bielecki (Administrator Bezpieczeństwa Informacji.).
          Podanie danych osobowych przez Użytkownika jest dobrowolne. Powierzone
          dane osobowe będą przetwarzane dla celów związanych z realizacją usług
          Serwisu. Dane te są chronione i przetwarzane, zgodnie z ustawą z dnia
          29 sierpnia 1997 r. o ochronie danych osobowych (t. jedn. Dz. U. z
          2002 Nr 101, poz. 926, z późn. zm.) Użytkownik ma prawo w każdym
          czasie dostępu do treści swoich danych, do ich korekty oraz żądania
          zaprzestania ich wykorzystywania lub ich usunięcia. W celu realizacji
          tego uprawnienia prosimy o przesłanie wiadomości e-mail na adres
          info@jbmultimedia.pl lub korespondencyjnie na adres siedziby JB
          Multimedia Jerzy Bielecki.
        </p>
        <p>
          4. Wszystkie dane publikowane w Serwisie oraz gromadzone w związku z
          jego funkcjonowaniem, a wskazane w pkt 1 powyżej, są rejestrowane i
          archiwizowane. Podane przez Użytkownika informacje mogą zostać
          udostępnione właściwym organom władzy publicznej, jeżeli wymagają tego
          obowiązujące przepisy prawa.
        </p>

        <h2>§ 5 Zasady korzystania z Serwisu</h2>

        <p>
          1. Zabronione jest korzystanie przez Użytkownika z usług oferowanych w
          ramach Serwisu w celach niezgodnych z prawem, niniejszym Regulaminem
          lub niezgodnie z celami utworzenia i funkcjonowania Serwisu.
        </p>

        <p>
          2. Użytkownik, korzystając z Serwisu, zobowiązany jest do korzystania
          z niego w sposób zgodny z Regulaminem, obowiązującym prawem, dobrymi
          obyczajami oraz w sposób nienaruszający praw osób trzecich oraz praw i
          interesów JB Multimedia Jerzy Bielecki. W szczególności Użytkownik
          zobowiązany jest do:
        </p>

        <ol type="a">
          <li>
            korzystania z Serwisu w sposób nie utrudniający lub zakłócający jego
            funkcjonowanie, w szczególności poprzez użycie określonego
            oprogramowania lub urządzeń. W przypadku stwierdzenia, że Użytkownik
            dopuszcza się takich działań, JB Multimedia Jerzy Bielecki ma prawo
            podjąć wszelkie działania prowadzące do naprawienia poniesionej w
            związku z tym szkody;
          </li>
          <li>
            korzystania z Serwisu w sposób nieuciążliwy dla innych Użytkowników,
            z poszanowaniem ich prywatności i wszelkich przysługujących im praw
            (w tym praw autorskich, praw wynikających z rejestracji znaków
            towarowych);
          </li>
          <li>
            niepodejmowania działań takich rozsyłanie i umieszczanie w Serwisie
            nie zamówionej informacji handlowej (tzw. spam) lub treści nie
            związanych z tematyką Serwisu;
          </li>
          <li>
            korzystania z wszelkich Materiałów umieszczonych w Serwisie jedynie
            w zakresie własnego użytku osobistego.
          </li>
        </ol>

        <p>
          3. Użytkownik powiadomi niezwłocznie JB Multimedia Jerzy Bielecki, o
          każdym przypadku naruszenia jego praw lub dóbr do w związku z
          korzystaniem z Serwisu, jak również o jakimkolwiek innym znanym mu
          przypadku naruszenia przepisów prawa lub zasad określonych w
          niniejszym Regulaminie, w związku z korzystaniem z Serwisu.
        </p>
        <p>
          4. Użytkownik może w każdym czasie zrezygnować z korzystania z Serwisu
          i wypisać się z otrzymywania newlettera wysyłając ze skrzynki e-mail
          zgodnej z podanym w formularzu zgłoszenia adresem e-mail na adres
          info@jbmultimedia.pl .
        </p>
        <h2>§ 6 Odpowiedzialność JB Multimedia Jerzy Bielecki</h2>

        <p>
          1. JB Multimedia Jerzy Bielecki prowadzi bieżący nadzór nad
          funkcjonowaniem Serwisu, zapewniający poprawność jego działania.
        </p>
        <p>
          2. JB Multimedia Jerzy Bielecki zastrzega sobie prawo do zawieszenia
          działania Serwisu, jak również do przejściowego zaprzestania działania
          Serwisu ze względu np. na czynności konserwacyjne.
        </p>

        <h2>§ 7 Reklamacje i kontakt z JB Multimedia Jerzy Bielecki</h2>

        <p>
          1. Reklamacje dotyczące usług świadczonych za pośrednictwem Serwisu
          można składać w formie pisemnej z dopiskiem „Serwis JB Multimedia” na
          adres: JB Multimedia Jerzy Bielecki, 21-500 Biała Podlaska, ul.
          Brzeska 107a, bądź też wysyłając wiadomość e-mail na adres:
          info@jbmultimedia.pl . Reklamacja powinna zawierać w swej treści:
          imię, nazwisko, dokładny adres zamieszkania, adres poczty
          elektronicznej, pod którym Użytkownik dokonał zgłoszenia w Serwisie,
          opis zgłaszanych zastrzeżeń, a w przypadku reklamacji pisemnej ponadto
          podpis Użytkownika składającego reklamację.
        </p>
        <p>
          2. JB Multimedia Jerzy Bielecki rozpatruje reklamację w ciągu 14
          (słownie: czternastu) dni roboczych od dnia jej otrzymania oraz
          informuje niezwłocznie Użytkownika, za pomocą poczty elektronicznej,
          listownie lub telefonicznie, o sposobie jej rozpatrzenia. W sytuacji,
          gdy podane w reklamacji dane lub informacje wymagają uzupełnienia, JB
          Multimedia Jerzy Bielecki zwraca się, przed rozpatrzeniem reklamacji,
          do składającego reklamację, o jej uzupełnienie. Wszelkie pytania czy
          sugestie dotyczące Serwisu można kierować e-mailem na adres
          info@jbmultimedia.pl.
        </p>

        <h2>§ 8 Postanowienia końcowe</h2>
        <p>
          1. W sprawach nie uregulowanych niniejszym Regulaminem stosuje się
          powszechnie obowiązujące przepisy prawa polskiego.
        </p>
        <p>
          2. Nieważność lub niemożliwość zrealizowania któregokolwiek z
          postanowień niniejszego Regulaminu nie wyklucza ważności i
          skuteczności pozostałych jego postanowień.
        </p>
        <p>
          3. Niniejszy Regulamin jest udostępniony w siedzibie JB Multimedia
          Jerzy Bielecki. Użytkownicy mogą także nieodpłatnie uzyskać dostęp do
          niniejszego Regulaminu w każdym czasie za pośrednictwem odsyłacza
          zamieszczonego na stronie głównej Serwisu www.jbmultimedia.pl oraz
          pobrać go i sporządzić jego wydruk.
        </p>
        <p>
          4. JB Multimedia Jerzy Bielecki zastrzega sobie prawo wprowadzenia
          zmian do niniejszego Regulaminu w przypadku zmiany przepisów prawa lub
          w celu usprawnienia działania Serwisu, a także poprawy ochrony
          prywatności Użytkowników i zapobieganiu nadużyciom. Zmiana jest
          skuteczna w stosunku do Użytkowników, w terminie 20 dni od daty
          poinformowania o zmianie Regulaminu. Użytkownik może złożyć
          oświadczenie o akceptacji lub braku akceptacji nowego Regulaminu,
          przesyłając swoje oświadczenie na adres JB Multimedia Jerzy Bielecki
          lub na adres e-mail info@jbmultimedia.pl . JB Multimedia Jerzy
          Bielecki z chwilą otrzymania oświadczenia o braku akceptacji zmian
          Regulaminu usunie dane Użytkownika i poinformuje go o tym fakcie.
        </p>
        <p>****</p>
        <h3>
          <b>Biała Podlaska 01.11.2023 r.</b>{" "}
        </h3>
        <p>Zapoznaj się z naszą:</p>
        <Link to="/polityka-prywatnosci">
          <h2>Polityką Prywatności</h2>
        </Link>
      </div>
    </Wrapper>
  </Layout>
);

export const Head = () => <Seo title="Regulamin" />;

export default statute;
