/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import "../assets/styles/layout.css";
import "../assets/styles/about.css";
import "../assets/styles/main-page.css";
import "../assets/styles/offer-page.css";
import "../assets/styles/news-page.css";
import "../assets/styles/contact-page.css";
import "../assets/styles/service-slider.css";
import "../assets/styles/opinion.css";
import "../assets/styles/advantages.css";
import "../assets/styles/news.css";
import "../assets/styles/handle-slider.css";
import "../assets/styles/offer-dev-page.css";
import "../assets/styles/offer-business-page.css";
import "../assets/styles/edu-page.css";
import "../assets/styles/qual-sign-page.css";
import "../assets/styles/showroomsPage.css";
import "../assets/styles/registers.css";
import "../assets/styles/printing-lease.css";
import "../assets/styles/services-offer-page.css";
import { Link } from "gatsby";
import styled from "styled-components";

const Logo = styled.div``;

const Socials = styled.div`
  margin-top: 20px;
  width: 50px;
  display: flex;
  justify-content: flex-start;
  align-items

`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <>
      <Header
        siteTitle={
          data.site.siteMetadata?.title ||
          `Firma JB Multimedia Biała Podlaska Laptopy, Drukarki, AGD`
        }
        // meta={
        //   data.site.siteMetadata?.meta || (
        //     <meta
        //       name="viewport"
        //       content="width=device-width, initial-scale=1"
        //     />
        //   )
        // }
      />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: `var(--size-content)`,
          padding: `var(--size-gutter)`,
        }}
      >
        <main>{children}</main>
        <div
          className="anchor"
          id="footerId"
          style={{ padding: "100px", top: "-250px" }}
        ></div>
        <footer
          style={{
            fontSize: `var(--font-sm)`,
          }}
        >
          <div className="footer-container">
            <div className="footer-container-wrapper">
              <ul>
                <strong>JB Multimedia Jerzy Bielecki</strong>
                <li>ul. Brzeska 107A</li>
                <li>21-500 Biała Podlaska</li>
                <li>+48 83 344 70 00</li>
                <li>info@jbmultimedia.pl</li>
                <br />
                <li>NIP: 537-123-42-90</li>
                <li>REGON: 030156724</li>
                <li>
                  {" "}
                  <Socials className="socials">
                    <Link
                      className="link"
                      to="https://www.facebook.com/jbmultimediapl"
                    >
                      <img
                        src={require("../assets/images/soc_fb.png").default}
                      />{" "}
                    </Link>
                  </Socials>
                </li>
              </ul>

              <ul>
                <strong>Menu</strong>
                <li>
                  <Link to="/o-nas">O nas</Link>
                </li>
                <li>
                  <Link to="/aktualnosci">Aktualności</Link>
                </li>
                <li>
                  <Link to="/salony">Salony</Link>
                </li>
                <li>
                  <Link to="https://jbm.pl/">Sklep On-line</Link>
                </li>
                <li>
                  <Link to="/kariera">Kariera</Link>
                </li>
                <li>
                  <Link to="/katalogi">Katalogi</Link>
                </li>
                <li>
                  <Link to="/kontakt">Kontakt</Link>
                </li>
                <br />
                <li>
                  <Link to="/regulamin">Regulamin</Link>
                </li>
                <li>
                  <Link to="/polityka-prywatnosci">Polityka prywatności</Link>
                </li>
              </ul>
              <ul>
                <strong>Oferta</strong>
                <li>
                  <Link to="/biznes-instytucja">Biznes & Instytucja</Link>
                </li>
                <li>
                  <Link to="/nowoczesna-edukacja">Nowoczesna edukacja</Link>
                </li>
                <li>
                  <Link to="/podpis-kwalifikowany">Podpis kwalifikowany</Link>
                </li>
                <li>
                  <Link to="/projektowanie-stron">Projektowanie stron</Link>
                </li>
                <li>
                  <Link to="/kasy-fiskalne-online">Kasy fiskalne</Link>
                </li>
              </ul>
              <ul>
                <strong> Nasze serwisy</strong>
                <li>
                  {" "}
                  <Link to="https://jbm.pl">www.jbm.pl</Link>
                </li>
                <li>
                  {" "}
                  <Link to="http://www.jbbudowa.pl">www.jbbudowa.pl</Link>
                </li>
                <li>
                  {" "}
                  <Link to="http://b2b.jb24.pl/login">b2b.jb24.pl</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="copyRight">
            <Logo className="logo">
              <Link to="/">
                <img src={require("../assets/images/logo_white.png").default} />{" "}
              </Link>
            </Logo>
            Copyright © 2023 &middot; JB Multimedia Jerzy Bielecki
            <br />
            Built by Adam Burawski
          </div>
        </footer>
      </div>
    </>
  );
};

export default Layout;
